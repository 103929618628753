import React, { useEffect } from 'react'

import { captureEvent } from '@sentry/browser'
import { isLocal, isProduction } from 'api/src/common/environment'
import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import { useLocation } from '@redwoodjs/router'

import useScrollTracker from 'src/lib/hooks//useScrollTracker'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useIntercomChat } from 'src/lib/hooks/UseIntercom'
import libUseLocalStorage from 'src/lib/hooks/UseLocalStorage'
import { useAuth } from 'web/src/Providers'

type MainLayoutProps = {
  children?: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { currentUser } = useAuth()
  const userId = currentUser?.userData?.id
  const clientId = currentUser?.membershipData?.clientId
  const clientName = currentUser?.userData?.activeMembership?.client?.name
  const path = useLocation()?.pathname
  const { trackEvent } = useAnalytics()

  const mixpanelToken = process.env.MIXPANEL_TOKEN
  const isGHAction = process.env.IS_GH_ACTION === 'true'
  if (!isGHAction && !isLocal) {
    mixpanel.init(mixpanelToken, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
    })
  }
  useEffect(() => {
    if (currentUser) {
      const membershipGroups = currentUser?.membershipData?.membershipGroups
      const membershipGroupsFriendly = membershipGroups
        ? membershipGroups.map((membershipGroup) => {
            return membershipGroup.name
          })
        : []

      const memberships = currentUser?.userData?.memberships
      const membershipsFriendly = memberships
        ? memberships.map((membership) => {
            return membership.client.name
          })
        : []

      const featuresForUser = currentUser?.featuresForUser.join(',')

      if (!isGHAction && !isLocal) {
        mixpanel.identify(currentUser?.userData?.email)

        const mixpanelSetProfile = (hasBrowserAuth: boolean) =>
          mixpanel.people.set({
            $name: currentUser?.userData?.name,
            $created: currentUser?.userData?.createdAt,
            // Custom properties (don't start with $)
            userId: currentUser?.userData?.id,
            userName: currentUser?.userData?.name,
            membershipId: currentUser?.userData?.activeMembership?.id,
            clientId: currentUser?.userData?.activeMembership?.client?.id,
            clientName: currentUser?.userData?.activeMembership?.client?.name,
            membershipRole: currentUser?.membershipData?.role,
            membershipGroups: membershipGroupsFriendly,
            memberships: membershipsFriendly,
            supportMode: currentUser?.isClientAlias,
            featuresForUser: featuresForUser,
            lastSeen: new Date().toISOString(),
            hasBrowserAuth,
          })

        const checkBrowserAuth =
          window.PublicKeyCredential
            ?.isUserVerifyingPlatformAuthenticatorAvailable ??
          (() => Promise.resolve(false))

        checkBrowserAuth()
          .then(mixpanelSetProfile)
          .catch((error) => {
            captureEvent({
              message: 'Error checking browser auth',
              level: 'info',
              extra: { error },
            })
            mixpanelSetProfile(false)
          })
      }
    }
  }, [currentUser])

  const handlePageVisibilityChange = () => {
    if (document.hidden) {
      // user goes to a different tab
      trackEvent('Pages', 'Navigate Away', { path: path })
    } else {
      // user comes back to the hub
      trackEvent('Pages', 'Navigate Back', { path: path })
    }
  }

  // record the first time a user used the browser and store in google analytics
  // this is used help track shared logins
  const [firstBrowserUse, setFirstBrowserUse] = libUseLocalStorage(
    'firstBrowserUse',
    null,
  )

  useEffect(() => {
    if (!firstBrowserUse) {
      setFirstBrowserUse(new Date().toISOString())
    }
  }, [firstBrowserUse, setFirstBrowserUse])

  useEffect(() => {
    if (userId) {
      ReactGA.initialize(isProduction ? 'G-S4G7RDLSD0' : 'G-XFZSLCW99C', {
        gaOptions: {
          page: path,
          hubsUserId: userId,
          hubsClientId: clientId,
          hubsClientName: clientName,
          userAgent: navigator.userAgent,
          firstBrowserUse: firstBrowserUse,
          // send userId a second time, but this time as user scoped rather then an event scoped
          userScopedHubsUserId: userId,
        },
      })
    }
  }, [currentUser])

  useEffect(() => {
    trackEvent('Pages', 'tracking', { path: path })
  }, [path])

  useEffect(() => {
    document.addEventListener('visibilitychange', handlePageVisibilityChange)

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handlePageVisibilityChange,
      )
    }
  })

  useScrollTracker(trackEvent)

  useIntercomChat()
  return <>{children}</>
}

export default MainLayout
