import React, { FC, useCallback, useEffect, useRef } from 'react'

import { BellAlertIcon, BellIcon } from '@heroicons/react/24/outline'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import { nextTickPromise } from 'api/src/common/utils'
import { useBoolean, useLocalStorage, useMediaQuery } from 'usehooks-ts'

import { useLocation } from '@redwoodjs/router'

import CustomBadge from 'src/components/Library/CustomBadge/CustomBadge'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import PageHeaderIconButton from '../PageHeaderIconButton/PageHeaderIconButton'

import NotificationsList from './NotificationsList'
import useNotification from './useNotification'

const minuteInMilliseconds = 60 * 1_000

// 30 mins
const slowPollingIntervalMilliseconds = 30 * minuteInMilliseconds

const viewPanelSearchParam = 'view-notifications=true'

const Notifications: FC = () => {
  const { currentUser } = useAuth()
  const { trackEvent } = useAnalytics()
  const { pathname, search } = useLocation()
  const isMobile = useMediaQuery('(max-width: 480px)')
  const showNotifications = useBoolean(false)
  const viewOnLoadIsDone = useBoolean(false)
  const anchorEl = useRef(null)
  const { getUnreadNotificationCount, getUnreadNotificationCountResult } =
    useNotification()
  const [unreadCount, setUnreadCount] = useLocalStorage(
    'notifications:unread-count',
    0,
  )

  useEffect(() => {
    if (currentUser.isClientAlias) {
      return
    }

    // Ensure other queries on the page get priority
    // By waiting for the next tick this is the last query to run on page load
    nextTickPromise().then(() => {
      getUnreadNotificationCount({
        fetchPolicy: 'network-only',
        nextFetchPolicy: () => 'network-only',
        pollInterval: slowPollingIntervalMilliseconds,
      })
    })
  }, [currentUser.isClientAlias, getUnreadNotificationCount])

  const handleIconClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault()
      trackEvent('Notifications', 'open notification modal')

      if (currentUser.isClientAlias) {
        return
      }

      showNotifications.toggle()
    },
    [currentUser.isClientAlias, showNotifications, trackEvent],
  )

  useEffect(() => {
    const data = getUnreadNotificationCountResult.data
    if (!data) {
      return
    }

    const count = data.notificationUnreadCountForMember
    setUnreadCount(count)

    if (
      search.includes(viewPanelSearchParam) &&
      !!anchorEl &&
      !viewOnLoadIsDone.value
    ) {
      trackEvent('Notifications', 'open notification modal via email')
      nextTickPromise().then(() => handleIconClick())
      viewOnLoadIsDone.toggle()

      // Clear the search param
      const searchWithoutViewNotifications = search
        .replace(`${viewPanelSearchParam}&`, '')
        .replace(`&${viewPanelSearchParam}`, '')
      history.pushState(null, '', pathname + searchWithoutViewNotifications)
    }
  }, [getUnreadNotificationCountResult, setUnreadCount, search, anchorEl])

  const getHandleIconClick = useCallback(() => {
    if (currentUser.isClientAlias) {
      return
    }
    return handleIconClick
  }, [currentUser.isClientAlias, handleIconClick])

  const handleClose = useCallback(() => {
    getUnreadNotificationCount()
    showNotifications.toggle()
    trackEvent('Notifications', 'close notification modal')
  }, [getUnreadNotificationCount, showNotifications])

  return (
    <>
      <Tooltip
        title={currentUser.isClientAlias ? 'Not available in support mode' : ''}
      >
        <div ref={anchorEl}>
          <CustomBadge
            badgeContent={currentUser.isClientAlias ? 0 : unreadCount}
            data-testid="notification-badge"
            className="text-white"
          >
            <PageHeaderIconButton
              disabled={currentUser.isClientAlias}
              data-testid="notification-badge"
              onClick={getHandleIconClick()}
            >
              {!currentUser.isClientAlias && unreadCount ? (
                <BellAlertIcon className="h-6 w-6" />
              ) : (
                <BellIcon className="h-6 w-6" />
              )}
            </PageHeaderIconButton>
          </CustomBadge>
        </div>
      </Tooltip>

      <Popover
        className={'mt-3'}
        open={showNotifications.value}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          root: 'shadow-none',
          paper: `rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 ${isMobile ? '!left-0' : ''}`,
        }}
        slotProps={{
          paper: {
            sx: {
              width: '100%',
              maxWidth: '28rem',
            },
          },
        }}
      >
        <div className={'m-4'}>
          <NotificationsList setUnreadCount={setUnreadCount} />
        </div>
      </Popover>
    </>
  )
}

export default Notifications
