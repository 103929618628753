import { isProduction } from 'api/src/common/environment'

import { Toaster } from '@redwoodjs/web/toast'

import ClientBillingSetupDialog from 'src/components/Billing/ClientBillingSetupDialog'
import ClientPauseDialog from 'src/components/ClientPauseDialog/ClientPauseDialog'
import Loading from 'src/components/Library/Loading'
import Sidebar from 'src/components/Sidebar'
import { useAuth } from 'web/src/Providers'

const HubLayout = ({ children }) => {
  const { currentUser } = useAuth()

  const isGHAction = process.env.IS_GH_ACTION === 'true'

  if ((!isProduction || isGHAction) && !currentUser?.roles) {
    // In GHAction and local, the Web side is ready before the API side
    setTimeout(() => {
      window.location.reload()
    }, 1500)
    return (
      <div className="grid h-screen place-items-center">
        <div className="flex flex-col gap-10">
          <Loading />
          <h1 className="text-gray-600">API is not ready - reloading</h1>
        </div>
      </div>
    )
  }
  // only show the client paused dialog for
  // client admins, editors, owners and members if activated
  // Stafflink or Superusers should not see the Account Suspended dialog

  return (
    <>
      <ClientPauseDialog />
      <ClientBillingSetupDialog />
      <Toaster toastOptions={{ className: 'rw-toast', duration: 2000 }} />
      <Sidebar>{children}</Sidebar>
    </>
  )
}

export default HubLayout
