import React, { Dispatch, useState, useEffect, useRef } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  TextField as MuiTextField,
  createTheme,
  ThemeProvider,
} from '@mui/material'

interface SearchFieldProps {
  label?: string
  className?: string
  placeholder?: string
  value: string
  onChange: Dispatch<string>
  autoFocus?: boolean
  debounced?: boolean
}

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    error: {
      main: '#ef4444',
    },
    background: {
      default: '#fff',
    },
  },
  shape: {
    borderRadius: 6,
  },
})

export const SearchField: React.FC<SearchFieldProps> = ({
  label,
  placeholder = 'Search',
  value,
  onChange,
  className,
  autoFocus = false,
  debounced = false,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    if (debounced) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
      debounceTimeout.current = setTimeout(() => {
        onChange(inputValue)
      }, 300)

      // Cleanup function to clear timeout if component unmounts or inputValue changes
      return () => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current)
        }
      }
    } else {
      onChange(inputValue)
    }
  }, [inputValue, debounced, onChange])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (!debounced) {
      onChange(event.target.value)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiTextField
        size="small"
        onChange={handleChange}
        className={`${className} w-full`}
        type="search"
        placeholder={placeholder}
        label={label}
        value={inputValue}
        InputProps={{
          startAdornment: (
            <MagnifyingGlassIcon className="h-6 w-6 mr-2 text-gray-400" />
          ),
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
    </ThemeProvider>
  )
}
